import { css } from 'astroturf';
import React from 'react';
import Link from '../link';

const styles = css`
  .accountWrapper {
    position: relative;
    opacity: 1;
    transition: 2s ease-in-out opacity;
    margin-right: 1rem;

    @media (max-width: 480px) {
      display: none;
    }

    @media (max-width: 56em) {
      margin-right: 2.5rem;
    }
  }
  .accountLink {
    position: relative;
    border: 0;
    background: none;
    width: 35px;
    height: 35px;
    color: #007ca5;
    display: grid;
    place-items: center;
    border-radius: 35px;
    transition: 0.25s ease-in-out background;

    [menuvisible] & {
      color: #fff;
    }

    &.highlight {
      opacity: 1;
    }

    &:hover,
    &:focus-visible {
      opacity: 1;
      background: #007ca522;
    }

    & svg {
      pointer-events: none;
      width: 24px;
      height: 24px;

      @media (forced-colors: active) {
        stroke: LinkText;
      }
    }
  }
`;

function AccountLink() {
  return (
    <div className={[styles.accountWrapper].join(' ')}>
      <Link
        data-tip="Manage your account"
        aria-label="Manage your account"
        to="https://dashboard.polypane.app/"
        className={[styles.accountLink].join(' ')}
        data-place="bottom"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-user"
          viewBox="0 0 24 24"
          strokeWidth="1.25"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
          <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
        </svg>
      </Link>
    </div>
  );
}

export default AccountLink;
