import { css } from 'astroturf';
import React from 'react';
import { Close } from 'react-bytesize-icons';
import Link from './link';

import data from '../data/releaseData';

const styles = css`
  .updatebar {
    position: sticky;
    top: 74px;
    z-index: 2;
    text-align: center;
    font-weight: bold;
    max-width: 33rem;
    margin: 0 auto;
    color: #fff;

    border-radius: 68px;
    background: rgb(69, 125, 72);
    transition:
      0.25s ease-in-out background,
      0.25s ease-in-out box-shadow,
      0.25s ease-in-out max-width;
    box-shadow:
      rgba(50, 50, 93, 0.17) 0px 5px 10px,
      rgba(255, 255, 255, 0.25) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.6) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.7) 0px 30px 60px -30px,
      rgba(103, 178, 111, 0.6) -10px 10px 60px -10px;

    &:has(a:hover, a:focus-visible, button:hover, button:focus-visible) {
      background: rgb(51, 51, 56);
      box-shadow:
        rgba(50, 50, 93, 0.47) 0px 5px 10px,
        rgba(255, 255, 255, 0.45) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.7) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.9) 0px 30px 60px -30px,
        rgba(103, 178, 111, 0.8) -10px 10px 60px -10px;
    }

    & > svg {
      position: absolute;
      left: 8px;
      top: 6px;
      width: 1.5rem;
      height: 1.5rem;
      z-index: 1;
    }

    a {
      position: relative;
      display: block;
      padding: 4px 8px;
      background: none;
      border-radius: 68px;
    }

    button {
      color: #fff;
      border: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 100%;
      background: rgba(255, 255, 255, 0);
      opacity: 0.65;
      display: grid;
      place-items: center;
      border-radius: 0 68px 68px 0;
      transition: 0.25s ease-in-out opacity;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

class UpdateBar extends React.Component {
  constructor() {
    super();
    this.state = {
      version: data[0].fullVersion,
      polypaneVersion: null,
      dismissed: false,
    };
  }

  componentDidMount() {
    this.setState({ dismissed: window.localStorage.getItem('dismissed-update') === this.state.version });

    if (window) {
      const { userAgent } = window.navigator;

      if (userAgent.includes('Polypane')) {
        const polypaneVersion = userAgent.match(/Polypane\/([0-9.]+)/);
        if (polypaneVersion) {
          this.setState({ polypaneVersion: polypaneVersion[1] });
        }
      }
    }
  }

  dismissPopup = () => {
    window.localStorage.setItem('dismissed-update', this.state.version);
    this.setState({ dismissed: true });
  };

  render() {
    const { dismissed, version, polypaneVersion } = this.state;

    if (dismissed || version === polypaneVersion || polypaneVersion === null) {
      return null;
    }
    return (
      <div className={styles.updatebar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-bell"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
          <path className={styles.bell} d="M9 17v1a3 3 0 0 0 6 0v-1" />
        </svg>
        <Link to="/download/">You are using an older version. Get Polypane {version} now!</Link>
        <button type="button" onClick={this.dismissPopup} aria-label="Dismiss" title="Dismiss">
          <Close width={12} height={12} strokeWidth={4} />
        </button>
      </div>
    );
  }
}

export default UpdateBar;
